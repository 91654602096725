import './RealEstateProps.css';
function RealEstateProps(props){
    return(
        <div className='RealEstatePropsContainer'>
            <div>
                <h4 className='h4REprops'>{props.heading}</h4>
            </div>
            <div className='RealEstatePropsProperties'>
                <img src={props.icon} alt='icon' className='iconSet'/><span className='propertiesCounter Props'>{props.amount} {props.type}</span>
            </div>
        </div>
    )
}

export default RealEstateProps;