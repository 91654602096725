import React from 'react';
import './PagesCss/ContactPage.css';  // Create a new CSS file for styling

function ContactPage() {
    return (
        <div className="contact-page">
            <h1>Contact Us</h1>
            <p>If you have any questions, feel free to reach out to us. We're happy to help!</p>

            <div className="contact-details">
                <div className="contact-info">
                    <h2>Our Contact Information</h2>
                    <p><strong>Phone:</strong> 0785 022 279</p>
                    <p><strong>Email:</strong> contact@atum-imobiliare.com</p>
                    <p><strong>Address:</strong> Strada Mare, Nr. 123, București</p>
                </div>

                <form className="contact-form">
                    <h2>Send Us a Message</h2>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" placeholder="Your Name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Your Email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" placeholder="Your Message" rows="5" required></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                </form>
            </div>
        </div>
    );
}

export default ContactPage;
