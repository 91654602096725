import React, {useCallback, useEffect, useState} from 'react';
import * as ScrollArea from '@radix-ui/react-scroll-area';

import MultiRangeSlider from "./MultiRangeSlider";

import './scrollArea.css';

const FilterBar = ({ className, initialPropertyType, ref1, data, searchText, setFilteredData, handleClose }) => {
    const [filterData, setFilterData] = useState({
        roomNumbers: [],
        bathNumbers: [],
        floorNumbers: [],
        propertyTypes: {
            display: [],
            filterValue: []
        },
        zoneType: [],
        zoneTypeExclude: [],
        spaceNumbers: [],
        maxPrice: 200000
    });

    const [filteredBy, setFilteredBy] = useState({
        roomNumbers: [],
        bathNumbers: [],
        floorNumbers: [],
        propertyTypes: initialPropertyType !== undefined ? [initialPropertyType] : [],
        zoneType: [],
        zoneTypeExclude: [],
        spaceNumbers: [],
        price: {
            min: 0,
            max: 200000000
        }
    });

    const filterValues = (valuesToFilter, filteredBy) => (
        valuesToFilter.filter((item) => (
            (filteredBy.propertyTypes === undefined || !filteredBy.propertyTypes.length || filteredBy.propertyTypes.includes(item.property_type_id)) &&
            (filteredBy.floorNumbers === undefined || !filteredBy.floorNumbers.length || filteredBy.floorNumbers.includes(item.floor)) &&
            (filteredBy.roomNumbers === undefined || !filteredBy.roomNumbers.length || filteredBy.roomNumbers.includes(item.rooms)) &&
            (filteredBy.bathNumbers === undefined || !filteredBy.bathNumbers.length || filteredBy.bathNumbers.includes(item.baths)) &&
            (filteredBy.zoneType === undefined || !filteredBy.zoneType.length || filteredBy.zoneType.includes(item.zone_name)) &&
            (filteredBy.zoneTypeExclude === undefined || !filteredBy.zoneTypeExclude.length || !filteredBy.zoneTypeExclude.includes(item.zone_name)) &&
            (filteredBy.spaceNumbers === undefined || !filteredBy.spaceNumbers.length || filteredBy.spaceNumbers.includes(item.parking_spaces)) &&
            (filteredBy.price === undefined || (filteredBy.price.max >= item.price && filteredBy.price.min <= item.price))
        ))
    );

    // Filter function
    useEffect(() => {
        let searchResult = data;
        if (searchText !== '') {
            searchResult = data.filter((item) => {
                return item.property_name.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.price.toString().includes(searchText) ||
                    item.rooms.toString().includes(searchText) ||
                    item.baths.toString().includes(searchText) ||
                    item.property_id.toString().includes(searchText) ||
                    item.description.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.use_sqm.toString().includes(searchText);
            });
        }

        const localFilteredData = filterValues(searchResult, filteredBy);

        setFilteredData(localFilteredData);
    }, [data, searchText, setFilteredData, filteredBy]);

    useEffect(() => {
        setFilterData(newFilteredData => {
            newFilteredData.roomNumbers = [...new Set(data.filter(item => item.rooms != null).map(item => item.rooms))].sort((a, b) => a - b);
            newFilteredData.bathNumbers = [...new Set(data.filter(item => item.baths != null).map(item => item.baths))].sort((a, b) => a - b);
            newFilteredData.floorNumbers = [...new Set(data.filter(item => item.floor != null).map(item => item.floor))].sort((a, b) => a - b);
            newFilteredData.propertyTypes.filterValue = [...new Set(data.filter(item => item.property_type_id != null).map(item => item.property_type_id))];
            newFilteredData.propertyTypes.display = [...new Set(data.filter(item => item.property_type != null).map(item => item.property_type))];
            newFilteredData.zoneType = [...new Set(data.filter(item => item.zone_name != null).map(item => item.zone_name))].sort((a, b) => a - b);
            newFilteredData.zoneTypeExclude = [...new Set(data.filter(item => item.zone_name != null).map(item => item.zone_name))].sort((a, b) => a - b);
            newFilteredData.spaceNumbers = [...new Set(data.filter(item => item.parking_spaces != null).map(item => item.parking_spaces))].sort((a, b) => a - b);
            newFilteredData.maxPrice = Math.max(...data.map(item => item.price || 0));

            return newFilteredData;
        });
    }, [data]);

    // Calculate how many properties match each filter option
    const countMatchingProperties = (filterName, filterValue) => {
        return filterValues(data, { ...filteredBy, [filterName]: [filterValue] }).length;
    };

    const addCheckboxFilter = (filterName, filterValue) => {
        let filteredByCopy = { ...filteredBy };
        const foundIndex = filteredByCopy[filterName].findIndex((name) => name === filterValue);
        if (foundIndex === -1) {
            filteredByCopy[filterName].push(filterValue);
        } else {
            filteredByCopy[filterName].splice(foundIndex, 1);
        }
        setFilteredBy(filteredByCopy);
    };

    const minMaxFilterHandler = useCallback(({ min, max }) => {
        if (filteredBy.price.min !== min || filteredBy.price.max !== max) {
            setFilteredBy(prev => ({
                ...prev,
                price: { min, max }
            }));
        }
    }, [filteredBy.price.min, filteredBy.price.max]);

    // Render filter options only if they yield results, and show counts
    const filterRooms = filterData.roomNumbers.map((item, index) => {
        const count = countMatchingProperties('roomNumbers', item);
        if (count > 0) {
            return (
                <div key={index} className='checkbox-container'>
                    <input onChange={() => addCheckboxFilter('roomNumbers', item)} type="checkbox" name={`${item} camere`} id={`${item}camere`} />
                    <label htmlFor={`${item}camere`}>{`${item} camere (${count})`}</label>
                </div>
            );
        }
        return null;
    });

    const filterBaths = filterData.bathNumbers.map((item, index) => {
        const count = countMatchingProperties('bathNumbers', item);
        if (count > 0) {
            return (
                <div key={index} className='checkbox-container'>
                    <input onChange={() => addCheckboxFilter('bathNumbers', item)} type="checkbox" name={`${item} bai`} id={`${item}bai`} />
                    <label htmlFor={`${item}bai`}>{`${item} bai (${count})`}</label>
                </div>
            );
        }
        return null;
    });

    const filterFloors = filterData.floorNumbers.map((item, index) => {
        const count = countMatchingProperties('floorNumbers', item);
        if (count > 0) {
            return (
                <div key={index} className='checkbox-container'>
                    <input onChange={() => addCheckboxFilter('floorNumbers', item)} type="checkbox" name={`${item}`} id={`etajul${item}`} />
                    <label htmlFor={`etajul${item}`}>{`${item} (${count})`}</label>
                </div>
            );
        }
        return null;
    });

    const filterPropertyType = filterData.propertyTypes.display.map((item, index) => {
        const count = countMatchingProperties('propertyTypes', filterData.propertyTypes.filterValue[index]);
        if (count > 0) {
            return (
                <div key={index} className='checkbox-container'>
                    <input onChange={() => addCheckboxFilter('propertyTypes', filterData.propertyTypes.filterValue[index])} type="checkbox" name={`${item}`} id={`${item}`}
                           checked={filteredBy.propertyTypes.includes(filterData.propertyTypes.filterValue[index])}
                    />
                    <label htmlFor={`${item}`}>{`${item} (${count})`}</label>
                </div>
            );
        }
        return null;
    });

    const filterZone = filterData.zoneType.map((item, index) => {
        const count = countMatchingProperties('zoneType', item);
        if (count > 0) {
            return (
                <div key={index} className='checkbox-container'>
                    <input onChange={() => addCheckboxFilter('zoneType', item)} type='checkbox' name={`${item}`} id={`${item}`} />
                    <label htmlFor={`${item}`}>{`${item} (${count})`}</label>
                </div>
            );
        }
        return null;
    });

    const filterZoneExclude = filterData.zoneTypeExclude.map((item, index) => {
        const count = countMatchingProperties('zoneTypeExclude', item);
        if (count > 0) {
            return (
                <div key={index} className='checkbox-container'>
                    <input onChange={() => addCheckboxFilter('zoneTypeExclude', item)} type='checkbox' name={`${item}`} id={`exclude${item}`} />
                    <label htmlFor={`exclude${item}`}>{`${item} (${count})`}</label>
                </div>
            );
        }
        return null;
    });

    const filterParkingSpaces = filterData.spaceNumbers.map((item, index) => {
        const count = countMatchingProperties('spaceNumbers', item);
        if (count > 0) {
            return (
                <div key={index} className='checkbox-container'>
                    <input onChange={() => addCheckboxFilter('spaceNumbers', item)} type='checkbox' name={`${item} locuri parcare`} id={`parking${item}`} />
                    <label htmlFor={`parking${item}`}>{`${item} locuri (${count})`}</label>
                </div>
            );
        }
        return null;
    });

    return (
        <ScrollArea.Root ref={ref1} className={className} style={{ position: 'fixed' }}>
            <ScrollArea.Viewport className="ScrollAreaViewport">
                <div style={{ padding: '15px 20px' }}>
                    <div className="Text">
                        Filtreaza
                        <button onClick={handleClose} className="closeButton">X</button>
                    </div>
                    <h5 className='filterHeading'>Tip Proprietate</h5>
                    {filterPropertyType}
                    <h5 className='filterHeading'>Zona</h5>
                    {filterZone}
                    <h5 className='filterHeading'>Pret</h5>
                    <MultiRangeSlider
                        min={0}
                        currentMax={filterData.maxPrice}
                        max={filterData.maxPrice}
                        onChange={minMaxFilterHandler}
                    />
                    <h5 className='filterHeading'>Exclude Zona</h5>
                    {filterZoneExclude}
                    <h5 className='filterHeading'>Numar Camere</h5>
                    {filterRooms}
                    <h5 className='filterHeading'>Numar Bai</h5>
                    {filterBaths}
                    <h5 className='filterHeading'>Etajul</h5>
                    {filterFloors}
                    <h5 className='filterHeading'>Locuri parcare</h5>
                    {filterParkingSpaces}
                </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
                <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
                <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Corner className="ScrollAreaCorner" />
        </ScrollArea.Root>
    );
};

export default FilterBar;
