import './HomeSeparator.css';
function HomeSeparator(props){
    return(
        <section className='separator'>
            <div className='alignSepText'>
                {props.children}
            </div>
        </section>
    )
}

export default HomeSeparator;