import './footer.css';
import logo from '../images/logos/logo.svg';

function Footer() {
    return(
        <section className='footer'>
            <div className='propertyFlex'><img className='logoFooter' src={logo} alt='Atum Imobiliare' /></div>
            <div className='propertyFlex'>
                <h3 className='white'>Agentie</h3>
                {/*<ul>
                    <li>Cumpara</li>
                    <li>Vinde</li>
                    <li>Inchiriaza</li>
                </ul>*/}
            </div>
        </section>
    )
}

export default Footer;