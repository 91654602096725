import './CategoryCard.css';
import Heading3 from "./Heading3";

function CategoryCard(props){
    return(
        <div className='flexBox'>
            <img src={props.image} alt={props.image} className="category-card-image"/>
                <Heading3 Class='category-card-title'>{props.title}</Heading3>
                <p className='amount'>{`${props.amount} proprietati`}</p>
        </div>
    )
}

export default CategoryCard;