// App.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function RealEstateListPage2() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('http://localhost/get_data.php');  //TODO MODIFY PATH FOR LIVE
            // TODO Add var vor get axios to make home and live deployment easier
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            <h1>Data from MySQL Database</h1>
            <ol>
                {data.map((item) => (
                    <li key={item.agent_id}>{item.nume + ' ' + item.prenume}</li>
                ))}
            </ol>
        </div>
    );
}

export default RealEstateListPage2;
