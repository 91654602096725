import { createContext, useState, useContext } from 'react';

// Create the PhoneContext
export const PhoneContext = createContext();

export const PhoneProvider = ({ children }) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    return (
        <PhoneContext.Provider value={{ phoneNumber, setPhoneNumber }}>
            {children}
        </PhoneContext.Provider>
    );
};

// Hook to use the phone context
export const usePhone = () => {
    return useContext(PhoneContext);
};
