import './Property.css';
import locationIcn from '../images/icons/locatie.svg';
import dormitorIcn from '../images/icons/dormitor.svg';
import baieIcn from '../images/icons/baie.svg';
import sqmIcn from '../images/icons/suprafata.svg';
import heartIcn from '../images/icons/favorite.svg';

function Property(props) {

    const imagePath = props.image === undefined ? 'images/properties/house1.png' : props.image.image_path;

    return(
        <div className='propertyContainer'>
            <img className='imageGridProperty' src={imagePath} alt={props.location} />
            <div className='propertyContent'>
                <div className='propertyFlex'>
                    <img src={heartIcn} alt='Favorite'/><h3 className='price'>{props.price} €</h3>
                </div>
                <div className='propertyFlex'><img src={locationIcn} alt='Locatie'/><p>{props.location}</p></div>
                <div className='propertyFlexLeft'>
                    <div className='propertyFlexItem'><img src={dormitorIcn} alt='Dormitoare'/><span className='propertiesCounter'>{props.bedrooms}</span></div>
                    <div><img src={baieIcn} alt='Bai' /><span className='propertiesCounter'>{props.bathrooms}</span></div>
                    <div><img src={sqmIcn} alt='Suprafata' /><span className='propertiesCounter'>{props.sqrM} m2</span></div>
                </div>
            </div>

        </div>
    )
}

export default Property;