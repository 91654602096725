// src/pages/Projects.js
import React from 'react';
import './PagesCss/Projects.css'; // Import the updated CSS file

function Projects() {
    const projectData = [
        {
            title: 'Project Title 1',
            description: 'Description of Project 1.',
            image: 'https://via.placeholder.com/300x200',
        },
        {
            title: 'Project Title 2',
            description: 'Description of Project 2.',
            image: 'https://via.placeholder.com/300x200',
        },
        // Add more projects as needed
    ];

    return (
        <div className="projects-container">
            <h1>Our Projects</h1>
            <p>Explore our range of projects showcasing our commitment to quality and innovation.</p>
            <div className="projects-grid">
                {projectData.map((project, index) => (
                    <div key={index} className="project-card">
                        <img src={project.image} alt={project.title} className="project-image" />
                        <h2 className="project-title">{project.title}</h2>
                        <p className="project-description">{project.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Projects;
