import { Carousel } from 'react-carousel-minimal';
import Heading2 from "../Components/Heading2";
import './PagesCss/productPage.css';
import LocationProductPage from "../Components/LocationProductPage";
import Call from '../images/icons/call.svg';
import SecondaryBtn from "../Components/SecondaryBtn";
import TourIcn from '../images/icons/virtualTour.svg';
import Map from '../images/icons/harta.svg';
import RealEstateProps from "../Components/RealEstateProps";
import Dormitor from '../images/icons/dormitor.svg';
import Baie from '../images/icons/baie.svg';
import Suprafata from '../images/icons/suprafata.svg';
import Garaj from '../images/icons/Garaj.svg';
import TransType from '../images/icons/Keys.svg';
import Floors from '../images/icons/floors.svg';
import Wall from '../images/icons/wall.svg';
import Property from "../Components/Property";


function ProductPage(){
    const data = [
        {
            image: "https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2075&q=80",
            caption: ""
        },
        {
            image: "https://images.unsplash.com/photo-1543489822-c49534f3271f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2232&q=80",
            caption: ""
        },
        {
            image: "https://images.unsplash.com/photo-1598928506311-c55ded91a20c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            caption: ""
        },
        {
            image: "https://images.unsplash.com/photo-1540518614846-7eded433c457?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1757&q=80",
            caption: ""
        },
        {
            image: "https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            caption: ""
        },
        {
            image: "https://plus.unsplash.com/premium_photo-1683573435684-e4ee18bf8588?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            caption: ""
        },
        {
            image: "https://images.unsplash.com/photo-1505692795793-20f543407193?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1739&q=80",
            caption: ""
        },
        {
            image: "https://images.unsplash.com/photo-1455831843141-7279e47411f7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            caption: ""
        },
        {
            image: "https://images.unsplash.com/photo-1613977257363-707ba9348227?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            caption: ""
        }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }
    return(
       <div>
            <div style={{
                padding: "0px"
            }}>
                <Carousel
                    data={data}
                    time={9000}
                    width="100vw"
                    height="80vh"
                    captionStyle={captionStyle}
                    radius="0px"
                    slideNumber={false}
                    slideNumberStyle={slideNumberStyle}
                    captionPosition="bottom"
                    automatic={true}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={true}
                    thumbnailWidth="100px"
                    style={{
                        textAlign: "center",
                        maxWidth: "100vw",
                        maxHeight: "80vh",
                        margin: "auto",
                    }}
                />
            </div>
            <div className='containerProductPage'>
                <div><Heading2 text='Casa cu piscina'/></div>
                <div><h2 className='price'>250.000 €</h2></div>
            </div>
           <div className='containerNarrow'>
                <div>
                    <LocationProductPage>Pictor Brana, Selimbar</LocationProductPage>
                </div>
               <div>
                   <SecondaryBtn src={Call} alt='Suna acum'>Suna acum</SecondaryBtn>
               </div>
           </div>
           <div className='containerNarrow'>
               <div>
                   <SecondaryBtn Class='spacing' src={TourIcn} alt='Tur virtual'>vezi tur virual</SecondaryBtn>
                   <SecondaryBtn Class='spacing'src={Map} alt='Harta'>vezi pe harta</SecondaryBtn>
               </div>
           </div>
           <div className='RealEstatePropsWrapper'>
               <RealEstateProps heading='Dormitoare' icon={Dormitor} amount='3'  />
               <RealEstateProps heading='Bai' icon={Baie} amount='2'  />
               <RealEstateProps heading='Suprafata Utila' icon={Suprafata} amount='125' type='mp' />
           </div>
           <div className='flexColumnNarrow'>
                <Heading2 text='Descriere'/>
               <p>
                   Această casă elegantă și spațioasă este o oază privată și confortabilă, perfectă pentru cei care doresc să se bucure de timpul petrecut acasă și să se relaxeze în stil. Cu o piscină mare în curte, vă veți bucura de o experiență de înot plăcută și răcoritoare, fie că este zi sau noapte.
               </p>
               <p>
                   Această casă este construită cu materiale de calitate superioară și are finisaje elegante, creând un ambient sofisticat și rafinat. Living-ul spațios și luminos este decorat cu gust și confortabil mobilat, oferind o zonă de relaxare perfectă pentru întreaga familie. De asemenea, există o chicinetă complet echipată, ideală pentru pregătirea de gustări și băuturi.
               </p>
               <p>
                   În zona de dormit, veți găsi camere spațioase și luminoase, fiecare cu o baie privată elegantă și amenajată cu toate cele necesare pentru a vă face sejurul cât mai confortabil posibil. De la balconul privat, vă puteți bucura de o priveliște spectaculoasă asupra piscinei și a curții.
               </p>
           </div>
           <div className='flexColumnNarrow'>
               <Heading2 text='Mai multe detalii'/>
               <div className='RealEstatePropsWrapperFullWidth'>
                   <RealEstateProps heading='Dormitoare' icon={Dormitor} amount='3'  />
                   <RealEstateProps heading='Bai' icon={Baie} amount='2'  />
                   <RealEstateProps heading='Suprafata Utila' icon={Suprafata} amount='125' type='mp' />
                   <RealEstateProps heading='Curte Libera' icon={Suprafata} amount='450' type='mp'  />
                   <RealEstateProps heading='Locuri de Parcare' icon={Garaj} amount='2'  />
                   <RealEstateProps heading='Tip tranzactie' icon={TransType} amount='Vanzare' />
                   <RealEstateProps heading='An constructie' icon={Wall} amount='2020'  />
                   <RealEstateProps heading='Numar etaje' icon={Floors} amount='2' />
               </div>
           </div>
           <div className='flexColumnNarrow'>
               <Heading2 text='Proprietati similare'/>
               <div className='realEstateListing'>
                   <Property image='./images/properties/house1.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />
                   <Property image='./images/properties/house1.png' price='100.000' location='Sibiu, Strada aviatorilor' />
                   <Property image='./images/properties/house1.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />
                   <Property image='./images/properties/house1.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />
                   <Property image='./images/properties/house1.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />
               </div>
           </div>
       </div>

    )
}

export default ProductPage;