import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import Footer from "./footer";
import { useEffect } from "react";
import { PhoneProvider } from "../Context/PhoneContext"; // Import PhoneContext

function RootLayout() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <PhoneProvider>
            <div>
                <MainNavigation />
                <Outlet />
                <Footer />
            </div>
        </PhoneProvider>
    );
}

export default RootLayout;
