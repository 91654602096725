import React, { useEffect, useState, useRef } from "react";
import HomeHeroImg from "../Components/HomeHeroImg";
import Heading2 from "../Components/Heading2";
import CategoryCard from "../Components/CategoryCard";
import HomeSeparator from "../Components/HomeSeparator";
import Property from "../Components/Property";
import { Link } from "react-router-dom";
import axios from "axios";

// Images imports
import ApartamenteImg from "../images/apartamente.png";
import AnsambluriRez from "../images/ansambluriRez.png";
import Case from '../images/case.png';
import Terenuri from '../images/terenuri.png';
import Spatii from '../images/spatii.png';

function HomePage() {
    const [data, setData] = useState([]);
    const [image, setImage] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('cumpara'); // State variable to track the selected category
    const categoriesRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        try {
            // const response = await axios.get('http://localhost/api/api/Properties.php?allProperties'); //TODO MODIFY PATH FOR LIVE
            // const imageResponse = await axios.get('http://localhost/api/api/Images.php?allImages'); //TODO modify the path for live build
            const response = await axios.get('https://project1.lbi.studio/api/api/Properties.php?allProperties'); //TODO MODIFY PATH FOR LIVE
            const imageResponse = await axios.get('https://project1.lbi.studio/api/api/Images.php?allImages'); //TODO modify the path for live build
            setData(response.data.properties);
            setImage(imageResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Scroll function
    const scrollToCategories = () => {
        if (categoriesRef.current) {
            categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const numarApartamente = data.filter(item => item.property_type_id === '1' && item.trans_id === '16');
    const numarApartamenteChirie = data.filter(item => item.property_type_id === '1' && item.trans_id === '14');
    const numarCase = data.filter(item => item.property_type_id === '2' && item.trans_id === '16');
    const numarCaseChirie = data.filter(item => item.property_type_id === '2' && item.trans_id === '14');
    const numarSpatiiComerciale = data.filter(item => item.property_type_id === '3' && item.trans_id === '16');
    const numarSpatiiComercialeChirie = data.filter(item => item.property_type_id === '3' && item.trans_id === '14');
    const numarTerenuri = data.filter(item => item.property_type_id === '4' && item.trans_id === '16');
    const numarSpatiiIndustriale = data.filter(item => item.property_type_id === '5' && item.trans_id === '16');
    const numarSpatiiIndustrialeChirie = data.filter(item => item.property_type_id === '5' && item.trans_id === '14');
    const numarSpatiiBirouri = data.filter(item => item.property_type_id === '6' && item.trans_id === '16');
    const numarSpatiiBirouriChirie = data.filter(item => item.property_type_id === '6' && item.trans_id === '14');
    const numarHoteluri = data.filter(item => item.property_type_id === '8' && item.trans_id === '16');
    const numarHoteluriChirie = data.filter(item => item.property_type_id === '8' && item.trans_id === '14');

    let dataApartamente = data.filter(item => item.trans_id === '16');
    const limitedApartamente = dataApartamente.slice(0, 8);

    let apartamente = null;
    if (image.length > 0 && limitedApartamente.length > 0) {
        apartamente = limitedApartamente.map((item) =>
            <Link to={`/product/${item.property_id}`} key={item.property_id} className="noStyleLink">
                <Property
                    image={image.find((imgEl) => imgEl.property_id === item.property_id)}
                    price={parseFloat(item.price).toLocaleString()}
                    location={item.property_name}
                    bedrooms={item.rooms}
                    bathrooms={item.baths}
                    sqrM={item.use_sqm}
                />
            </Link>
        );
    }

    return (
        <div className="App">
            <section className="App-header">
                <HomeHeroImg scrollToCategories={scrollToCategories} />
            </section>
            <div ref={categoriesRef}>
                <Heading2 text='Cauta dupa Categorii' />
            </div>
            <div className='categoryCard'>
                <div className='center'>
                    <ul>
                        <li>
                            <button
                                className={selectedCategory === 'cumpara' ? 'mainNavLinksActive' : 'mainNavLinks'}
                                onClick={() => setSelectedCategory('cumpara')}
                            >
                                Cumpara
                            </button>
                        </li>
                        <li>
                            <button
                                className={selectedCategory === 'inchiriaza' ? 'mainNavLinksActive' : 'mainNavLinks'}
                                onClick={() => setSelectedCategory('inchiriaza')}
                            >
                                Inchiriaza
                            </button>
                        </li>
                    </ul>
                </div>

                    {selectedCategory === 'cumpara' ? (
                        <>
                            {
                                numarApartamente.length > 0 && (
                                    <Link to={`/proprietati/1`} className="noStyleLink">
                                        <CategoryCard image={ApartamenteImg} title='Apartamente' amount={numarApartamente.length} />
                                    </Link>
                                )
                            }
                            {
                                numarCase.length > 0 && (
                                    <Link to={`/proprietati/2`} className="noStyleLink">
                                        <CategoryCard image={Case} title='Case' amount={numarCase.length} />
                                    </Link>
                                )
                            }
                            {
                                numarTerenuri.length > 0 && (
                                    <Link to={`/proprietati/4`} className="noStyleLink">
                                        <CategoryCard image={Terenuri} title='Terenuri' amount={numarTerenuri.length} />
                                    </Link>
                                )
                            }
                            {
                                numarSpatiiComerciale.length > 0 && (
                                    <Link to={`/proprietati/3`} className="noStyleLink">
                                        <CategoryCard image={Spatii} title='Spatii' amount={numarSpatiiComerciale.length} />
                                    </Link>
                                )
                            }

                            {
                                numarApartamenteChirie.length > 0 && (
                                    <Link to={`/proprietati/1`} className="noStyleLink">
                                        <CategoryCard image={AnsambluriRez} title='Ansambluri Rezidentiale' amount='10' />
                                    </Link>
                                )
                            }
                        </>
                    ) : (
                        <>
                            {numarApartamenteChirie.length > 0 && (
                                <Link to={`/inchiriaza/1`} className="noStyleLink">
                                    <CategoryCard image={ApartamenteImg} title='Apartamente' amount={numarApartamenteChirie.length} />
                                </Link>
                            )}
                            {numarCaseChirie.length > 0 && (
                                <Link to={`/inchiriaza/2`} className="noStyleLink">
                                    <CategoryCard image={Case} title='Case' amount={numarCaseChirie.length} />
                                </Link>
                            )}
                            {numarSpatiiComercialeChirie.length > 0 && (
                                <Link to={`/inchiriaza/3`} className="noStyleLink">
                                    <CategoryCard image={Spatii} title='Spatii' amount={numarSpatiiComercialeChirie.length} />
                                </Link>
                            )}
                        </>
                    )}

            </div>
            <HomeSeparator>
                <span className='display-text'>
                    IMPREUNA<br />
                    <span className='lowPriority'>gasim</span> CASA<br />
                    PERFECTA!
                </span>
            </HomeSeparator>
            <Heading2 text='Cele mai Recente Oferte' />
            <div className='homePropertyGridContainer'>
                {apartamente}
            </div>
        </div>
    );
}

export default HomePage;
