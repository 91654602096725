import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import RealEstateListPage from "./Pages/RealEstateList";
import ProductPage from "./Pages/ProductPage";
import RootLayout from "./Components/Root";
import RealEstateListPage2 from "./Pages/RealEstateList2";
import ProductDetail from "./Pages/ProductDetail";
import ContactPage from "./Pages/ContactPage";
import Projects from "./Pages/Projects";

const router = createBrowserRouter([
    {   path: '/',
        element: <RootLayout/>,
        children: [
            { path: '/', element: <HomePage />},
            { path: '/proprietati', element: <RealEstateListPage />},
            { path: '/proprietati/:type', element: <RealEstateListPage />}, // Added dynamic path
            { path: '/proprietatiTest', element: <RealEstateListPage2 />},
            { path: '/casa', element: <ProductPage />},
            { path: '/inchiriaza', element: <RealEstateListPage chirie />},
            { path: '/inchiriaza/:type', element: <RealEstateListPage chirie />},
            { path: '/product/:productId', element: <ProductDetail />},
            { path: '/contact', element: <ContactPage />},
            { path: '/proiecte', element: <Projects />},
        ],}
])


function App() {

    return (
    <RouterProvider router={router} />
  );
}

export default App;
