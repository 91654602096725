import './HomeHeroImg.css';
import { useRef } from 'react';

function HomeHeroImg({ scrollToCategories }) {
    return (
        <section className='homeHeader'>
            <div className='headerContent'>
                <p>Descopera</p>
                <h1 className='headerText'>Casa Visurilor Tale</h1>
                <button onClick={scrollToCategories} className="noStyleLink mainBtn deepBlue">
                    Vezi proprietati
                </button>
            </div>
        </section>
    );
}

export default HomeHeroImg;
