import { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom'; // Import useLocation
import { usePhone } from '../Context/PhoneContext'; // Import the PhoneContext hook
import LogoHeader from '../images/logos/logoHeader.svg';
import LogoHeaderWhite from '../images/logos/logo.svg'; // Import white logo
import './MainNavigation.css';
import PhoneIcon from '../images/icons/phone.svg';

function formatPhoneNumber(number) {
    // Remove non-digit characters
    const cleanNumber = number.replace(/\D/g, '');

    // Format as xxxx xxx xxx
    return cleanNumber.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
}

function MainNavigation() {
    const { phoneNumber, setPhoneNumber } = usePhone(); // Get and set phone number from context
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isHomePage, setIsHomePage] = useState(false);
    const location = useLocation(); // Track the current route

    // Handle scroll event
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Determine if it's the home page (assuming '/' is the home page route)
    useEffect(() => {
        setIsHomePage(location.pathname === '/');
    }, [location.pathname]);

    // Rollback phone number when leaving the product detail page
    useEffect(() => {
        const productDetailPageRegex = /^\/product\/\d+$/; // Product detail page path

        // If the current path is not a product detail page
        if (!productDetailPageRegex.test(location.pathname)) {
            // Reset phone number to default
            setPhoneNumber('0785022279');
        }
    }, [location.pathname, setPhoneNumber]);

    return (
        <header className={`${isScrolled || !isHomePage ? 'white-background' : 'transparent'}`}>
            <div>
                <div className='phoneHolder'>
                    <img src={PhoneIcon} alt='Phone' />
                    {formatPhoneNumber(phoneNumber || '0785022279')} {/* Format phone number */}
                </div>
                <div className='headerFlex'>
                    <div className='logo'>
                        <Link to='/'>
                            <img
                                src={isScrolled || !isHomePage ? LogoHeader : LogoHeaderWhite}
                                alt='ATUM Imobiliare'
                            />
                        </Link>
                    </div>
                    <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
                        &#9776;
                    </button>
                    <nav className={menuOpen ? 'nav-open' : ''}>
                        <ul>
                            <li>
                                <NavLink to='/proprietati' className={({ isActive }) => (isActive ? 'mainNavLinksActive' : 'mainNavLinks')}>
                                    Cumpara
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/inchiriaza' className={({ isActive }) => (isActive ? 'mainNavLinksActive' : 'mainNavLinks')}>
                                    Inchiriaza
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/proiecte' className={({ isActive }) => (isActive ? 'mainNavLinksActive' : 'mainNavLinks')}>
                                    Proiectele Noastre
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/contact' className={({ isActive }) => (isActive ? 'mainNavLinksActive' : 'mainNavLinks')}>
                                    Contact
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default MainNavigation;
